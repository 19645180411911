.table {
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid #ebf1ff;
}
div.table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Handle */
div.table::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

/* Handle on hover */
div.table::-webkit-scrollbar-thumb:hover {
  background: #555;
}
