.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  content: "13";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
  content: "15";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
  content: "17";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before {
  content: "12";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="13px"]::before {
  content: "13";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before {
  content: "14";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="15px"]::before {
  content: "15";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before {
  content: "16";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="17px"]::before {
  content: "17";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before {
  content: "18";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before {
  content: "20";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before {
  content: "24";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before {
  content: "28";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before {
  content: "36";
}

/* Set default font-family */
.app .ql-editor {
  font-family: "Ubuntu";
}

/* Set dropdown font-families */

.ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  font-family: Arial, Helvetica, sans-serif;
  content: "Arial";
}
.ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  font-family: "georgia";
  content: "Georgia";
}
.ql-picker.ql-font .ql-picker-label[data-value="nunito"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="nunito"]::before {
  font-family: "nunito", cursive;
  content: "Nunito";
}
.ql-picker.ql-font .ql-picker-label[data-value="calibri"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="calibri"]::before {
  font-family: "calibri";
  content: "Calibri";
}
.ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before {
  font-family: "roboto";
  content: "Roboto";
}
.ql-picker.ql-font .ql-picker-label[data-value="lucida-console"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="lucida-console"]::before {
  font-family: "Lucida Console", "Courier New", monospace;
  content: "Lucida Console";
  width: 80px;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}
.ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
  font-family: "Times New Roman", Times, serif;
  content: "Times New Roman";
  width: 80px;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.ql-snow .ql-picker.ql-size {
  min-width: 60px;
  width: auto;
}

/* Set content font-families */
.ql-font-roboto {
  font-family: "roboto";
}
.ql-font-calibri {
  font-family: "calibri";
}
.ql-font-arial {
  font-family: Arial, Helvetica, sans-serif;
}
.ql-font-georgia {
  font-family: "georgia";
}
.ql-font-nunito {
  font-family: "nunito", cursive;
}
.ql-font-lucida-console {
  font-family: "Lucida Console", "Courier New", monospace;
}
.ql-font-times-new-roman {
  font-family: "Times New Roman", Times, serif;
}
