math-field::part(virtual-keyboard-toggle) {
  color: red;
}

math-field::part(container) {
  height: 100% !important;
}

math-field::part(content) {
  display: flex;
  justify-content: center !important;
  align-items: center;
}

math-field::part(virtual-keyboard-toggle) {
  display: none;
}

math-field::part(menu-toggle) {
  display: none;
}

body > .ML__keyboard {
  z-index: 999999;
}
