.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: fixed;
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  z-index: 9999;
  left: 0px;
  top: 0px;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

// .loader {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-width: 100vw;
//   height: 100vh;
//   background: rgb(255, 255, 255);
//   position: fixed;
// }
// .loader > div {
//   width: 20px;
//   height: 20px;
//   background: #8385aa;
//   opacity: 0.5;
//   border-radius: 50%;
//   margin: 3px;
//   animation: loader 0.6s infinite alternate;
// }
// .loader > div:nth-child(2) {
//   animation-delay: 0.2s;
// }
// .loader > div:nth-child(3) {
//   animation-delay: 0.4s;
// }
// @keyframes loader {
//   to {
//     opacity: 0.1;
//     transform: translate(0, -20px);
//     background: pink;
//   }
// }

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-width: 100%;
  // width: 100%;
  // height: 100%;
  background: rgb(255, 255, 255);
  // position: fixed;
}

.f1 {
  position: relative;
  top: 0;
  left: 0;
  animation: rotation1 8s infinite linear;
}

.f2 {
  position: absolute;
  animation: rotation2 8s infinite linear;
  // top: 0;
  // left: 0;
}

@keyframes rotation1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation2 {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
